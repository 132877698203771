import React, { useContext, useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import { DropdownContext } from "./DropdownContext";
import { cacheBuster } from "./config";

function NewsChannels() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dropdownContext = useContext(DropdownContext);

  if (!dropdownContext) {
    throw new Error("DropdownContext must be used within DropdownProvider");
  }

  const { selectedOption } = dropdownContext;

  // Add new logo name variable here at the end after "tamiljanam", For eg: "newlogoname",
  const [tabs] = useState([
    "sunnews",
    "puthiyathalaimurai",
    "thanthitv",
    "news18tamilnadu",
    "polimernews",
    "jayaplus",
    "kalaignarnews",
    "newstamil24x7",
    "zeetamilnews",
    "tamiljanam",
  ]);

  const [selectedTamilTab, setSelectedTamilTab] = useState<string>(
    () => localStorage.getItem("selectedTamilTab") || "sunnews"
  );

  const handleTabClick = (channelKey: string) => {
    setSelectedTamilTab(channelKey);
    localStorage.setItem("selectedTamilTab", channelKey);
  };

  useEffect(() => {
    const savedTab = localStorage.getItem("selectedTamilTab");
    if (savedTab) {
      setSelectedTamilTab(savedTab);
    }
  }, []);

  useEffect(() => {
    const iframe = document.getElementById("videoFrame") as HTMLIFrameElement;
    if (iframe) {
      const src = iframe.src;
      iframe.src = src + "&autoplay=1";
    }
  }, []);

  const [visibleTabs, setVisibleTabs] = useState(tabs);
  // Add channel name in "" which you want to hide with comma separated. For eg: const hiddenTabs: any = ["sunnews", "puthiyathalaimurai"];
  const hiddenTabs: any = ["polimernews", "jayaplus"];

  useEffect(() => {
    const updatedVisibleTabs = visibleTabs.filter(
      (tab) => !hiddenTabs.includes(tab)
    );
    setVisibleTabs(updatedVisibleTabs);

    if (hiddenTabs.includes(selectedTamilTab)) {
      const nextVisibleTab = updatedVisibleTabs[0];
      if (nextVisibleTab) {
        setSelectedTamilTab(nextVisibleTab);
      }
    }
  }, []);

  /*****  To add new logo add this line with new logo name start ******/
  const sunnews = `/assets/channel-logos/sunnews.png?v=${cacheBuster}`;
  /*****  To add new logo add this line with new logo name end ******/
  const puthiyathalaimurai = `/assets/channel-logos/puthiya-thalaimurai.jpeg?v=${cacheBuster}`;
  const thanthitv = `/assets/channel-logos/thanthitv.jpeg?v=${cacheBuster}`;
  const news18tamilnadu = `/assets/channel-logos/news18-tamil-nadu.jpeg?v=${cacheBuster}`;
  const polimernews = `/assets/channel-logos/polimer-news.jpeg?v=${cacheBuster}`;
  const jayaplus = `/assets/channel-logos/jaya-plus.jpeg?v=${cacheBuster}`;
  const kalaignarnews = `/assets/channel-logos/kalaignar-news.jpeg?v=${cacheBuster}`;
  const newstamil24x7 = `/assets/channel-logos/news-tamil-24x7.jpeg?v=${cacheBuster}`;
  const zeetamilnews = `/assets/channel-logos/zee-tamil-news.jpeg?v=${cacheBuster}`;
  const tamiljanam = `/assets/channel-logos/tamil-janam.jpeg?v=${cacheBuster}`;

  const imagesToPreload = [
    sunnews,
    puthiyathalaimurai,
    thanthitv,
    news18tamilnadu,
    polimernews,
    jayaplus,
    kalaignarnews,
    newstamil24x7,
    zeetamilnews,
    tamiljanam,
  ];

  imagesToPreload.forEach((src) => {
    const img = new Image();
    img.src = src;
  });

  return (
    <>
      <div className="Apps">
        <Helmet>
          <title>
            Live Tamil News | Sun News, Puthiya Thalaimurai, Thanthi TV, and
            More
          </title>
          <meta
            name="description"
            content="Watch live Tamil news on Tamil-News.in with updates from Sun News, Puthiya Thalaimurai, Thanthi TV, News18 Tamil Nadu, Polimer News, and more Tamil channels."
          />
          <meta
            name="keywords"
            content="Tamil news live, Tamil news channels, Sun News live, Puthiya Thalaimurai live, Thanthi TV live, News18 Tamil Nadu live, Polimer News live, Jaya Plus live, Kalaignar News live, Zee Tamil News live, Tamil Janam TV live"
          />
        </Helmet>
        <Header />

        {selectedOption === "Tamil" && (
          <>
            {/* Tabs for channel logos */}
            <div className="logo-grid">
              <div className="tabs">
                {/******  To add new logo copy paste this div(from line number 133 to 152) and change channel name wherever necessary and change the order start *******/}
                {visibleTabs.includes("sunnews") && (
                  <div
                    className={`tab-item ${
                      selectedTamilTab === "sunnews" ? "active" : ""
                    } order-1`}
                    onClick={() => handleTabClick("sunnews")}
                  >
                    {/**** When you add new logo change the src={sunnews} to src={newlogoname} in below div start ****/}
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={sunnews}
                        alt="Sun News Live"
                      />
                    </div>
                    {/**** When you add new logo change the src={sunnews} to src={newlogoname} in below div end ****/}
                  </div>
                )}
                {/******  To add new logo copy paste this div and change channel name wherever necessary and change the order end *******/}

                {visibleTabs.includes("puthiyathalaimurai") && (
                  <div
                    className={`tab-item ${
                      selectedTamilTab === "puthiyathalaimurai" ? "active" : ""
                    } order-2`}
                    onClick={() => handleTabClick("puthiyathalaimurai")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={puthiyathalaimurai}
                        alt="Puthiya Thalaimurai Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("thanthitv") && (
                  <div
                    className={`tab-item ${
                      selectedTamilTab === "thanthitv" ? "active" : ""
                    } order-3`}
                    onClick={() => handleTabClick("thanthitv")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={thanthitv}
                        alt="Thanthi TV Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("news18tamilnadu") && (
                  <div
                    className={`tab-item ${
                      selectedTamilTab === "news18tamilnadu" ? "active" : ""
                    } order-4`}
                    onClick={() => handleTabClick("news18tamilnadu")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={news18tamilnadu}
                        alt="News18 Tamil Nadu Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("polimernews") && (
                  <div
                    className={`tab-item ${
                      selectedTamilTab === "polimernews" ? "active" : ""
                    } order-5`}
                    onClick={() => handleTabClick("polimernews")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={polimernews}
                        alt="Polimer News Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("jayaplus") && (
                  <div
                    className={`tab-item ${
                      selectedTamilTab === "jayaplus" ? "active" : ""
                    } order-6`}
                    onClick={() => handleTabClick("jayaplus")}
                  >
                    <div className="image-container">
                      <img
                        rel="preload"
                        height="80"
                        width="80"
                        src={jayaplus}
                        alt="Jaya Plus Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("kalaignarnews") && (
                  <div
                    className={`tab-item ${
                      selectedTamilTab === "kalaignarnews" ? "active" : ""
                    } order-7`}
                    onClick={() => handleTabClick("kalaignarnews")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={kalaignarnews}
                        alt="Kalaignar News Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("newstamil24x7") && (
                  <div
                    className={`tab-item ${
                      selectedTamilTab === "newstamil24x7" ? "active" : ""
                    } order-8`}
                    onClick={() => handleTabClick("newstamil24x7")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={newstamil24x7}
                        alt="News Tamil 24x7 Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("zeetamilnews") && (
                  <div
                    className={`tab-item ${
                      selectedTamilTab === "zeetamilnews" ? "active" : ""
                    } order-9`}
                    onClick={() => handleTabClick("zeetamilnews")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={zeetamilnews}
                        alt="Zee Tamil News Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("tamiljanam") && (
                  <div
                    className={`tab-item ${
                      selectedTamilTab === "tamiljanam" ? "active" : ""
                    } order-10`}
                    onClick={() => handleTabClick("tamiljanam")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={tamiljanam}
                        alt="Tamil Janam TV Live"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Display the selected YouTube video */}

            <div className="video-container">
              {/******  To add new youtube video iframe copy paste this part and change channel name wherever necessary start  *******/}
              <h1 style={{ display: "none" }}>
                Live Tamil News Channels – Watch Sun News, Thanthi TV, Puthiya
                Thalaimurai & More
              </h1>
              {selectedTamilTab === "sunnews" &&
                visibleTabs.includes("sunnews") && (
                  <>
                    <h2>Sun News Live</h2>
                    <iframe
                      id="videoFrame"
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/9M02G5c6x6w?si=eQ_ANZK0h9zeUABH&autoplay=1&v=${cacheBuster}`}
                      title="Sun News Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {/******  To add new youtube video iframe copy paste this part and change channel name wherever necessary end *******/}

              {selectedTamilTab === "puthiyathalaimurai" &&
                visibleTabs.includes("puthiyathalaimurai") && (
                  <>
                    <h2>Puthiya Thalaimurai Live</h2>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/RUzgphgBRzI?si=o42evO6sqoAjX6zl&autoplay=1&v=${cacheBuster}`}
                      title="Puthiya Thalaimurai Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedTamilTab === "thanthitv" &&
                visibleTabs.includes("thanthitv") && (
                  <>
                    <h2>Thanthi TV Live</h2>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/j1nzTy5q_1Y?si=YyLG1S1ZEQcgPdjH&autoplay=1&v=${cacheBuster}`}
                      title="Thanthi TV Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedTamilTab === "news18tamilnadu" &&
                visibleTabs.includes("news18tamilnadu") && (
                  <>
                    <h2>News18 Tamil Nadu Live</h2>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/E4ndYFfdlb8?si=VUZhgmwpAl7nYp0y&autoplay=1&v=${cacheBuster}`}
                      title="News18 Tamil Nadu Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedTamilTab === "polimernews" &&
                visibleTabs.includes("polimernews") && (
                  <>
                    <h2>Polimer News Live</h2>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/GdkFR6j-sSg?si=bX6fj-4-v3266t50&autoplay=1&v=${cacheBuster}`}
                      title="Polimer News Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedTamilTab === "jayaplus" &&
                visibleTabs.includes("jayaplus") && (
                  <>
                    <h2>Jaya Plus Live</h2>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/ZwD3-hUQ3rk?si=4yw69F8fGIaH8ZrP&autoplay=1&v=${cacheBuster}`}
                      title="Jaya Plus Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedTamilTab === "kalaignarnews" &&
                visibleTabs.includes("kalaignarnews") && (
                  <>
                    <h2>Kalaignar News Live</h2>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/s_OLmJOVXdU?si=X25EGJ_DDbVyNaXg&autoplay=1&v=${cacheBuster}`}
                      title="Kalaignar News Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedTamilTab === "newstamil24x7" &&
                visibleTabs.includes("newstamil24x7") && (
                  <>
                    <h2>News Tamil 24x7 Live</h2>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/rn1s5DWPGyE?si=7C8KaunJjLmuCw21&autoplay=1&v=${cacheBuster}`}
                      title="News Tamil 24x7 Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedTamilTab === "zeetamilnews" &&
                visibleTabs.includes("zeetamilnews") && (
                  <>
                    <h2>Zee Tamil News Live</h2>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/Zzo7cX7bJZQ?si=MBodsKEopw1RodsV&autoplay=1&v=${cacheBuster}`}
                      title="Zee Tamil News Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedTamilTab === "tamiljanam" &&
                visibleTabs.includes("tamiljanam") && (
                  <>
                    <h2>Tamil Janam TV Live</h2>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/xjiP6cLotFo?si=NBwl63VEq-gxmSF8&autoplay=1&v=${cacheBuster}`}
                      title="Tamil Janam TV Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}
            </div>
          </>
        )}

        <p style={{ display: "none" }}>
          Welcome to Tamil News, your one-stop platform for live streaming Tamil
          news channels. Stay updated with the latest breaking news from Tamil
          Nadu, India, and the world by watching live feeds from the top Tamil
          news channels. From political updates to entertainment, we bring you
          uninterrupted live news from the most trusted sources.
        </p>
      </div>
      <Footer />
    </>
  );
}

export default NewsChannels;
